/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

.btn.front:hover {
	border-color: darken(#02B875, 10%);
	color: darken(#1C9963, 10%);
}

.btn.front {
	@media (max-width:767px) {
		display: block;
        max-width: 320px;
	}
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax"
;
